import React from "react";
import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          width={1500}
          height={5}
          object-fill="none"
        />
        <title>Корзини Сувеніри</title>
        <p>Вітаємо на сайті Корзини Сувеніри</p>
        <h1>Вибачте, але сайт наразі знаходиться в розробці</h1>
      </header>
    </div>
  );
}

export default App;
